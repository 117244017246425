import "./App.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "./utils/routes";
import Public from "./publicRoutes";
import Protected from "./protectedRoute";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {PUBLIC_ROUTES.map((route, index) => (
        <Public
          exact
          {...route}
          key={index}
        />
      ))}
      {PROTECTED_ROUTES.map((route, index) => (
        <Protected
          exact
          {...route}
          key={index}
        />
      ))}
    </>
  );
}

export default App;
