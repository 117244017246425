export const REACT_APP_KEY_NAME = "parcel-admin-key"
export const LOADER_OVERRIDE = {
  display: "block",
  margin: "0 auto",
  border: "3px solid",
  borderColor: "#FFFFFF",
}

export const SELECT_STYLE = {
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    border: 0,
    padding: "4.5px !important",
    backgroundColor: "#efefef",
    borderRadius: "var(--border_radius) !important",
    marginBottom: "0.8rem",
  }),
}