import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import { Link, useHistory } from "react-router-dom";
import Api from "../api/api";

const HEAD = ["Package", "Return Info", "Receiver Info"];

const Dashboard = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  const _data = useMemo(() => {
    if (!search.trim()) {
      return data;
    }

    return data.filter(
      (item) =>
        item?.shipping_address?.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.return_address?.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item?.tracking_number.toLowerCase().includes(search.toLowerCase()),
    );
  }, [data, search]);

  const getData = useCallback(async () => {
    const res = await Api.getShipments();
    if (res?.data?.success) {
      if (res?.data?.pendingShipments?.length > 0) {
        setData(res.data?.pendingShipments[0]?.shipments || []);
      }
    }
  }, [setData]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <div className="flex w-full items-center flex-wrap justify-between gap-4 mb-5">
          <h2 className="text-3xl sm:text-[40px] font-[600]">Shipments</h2>
          <div className="flex items-center flex-wrap-reverse gap-4">
            <div className="relative">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="border-[1px] border-[#0000004D] rounded-md pl-3 pr-7 py-3"
                placeholder="Search Parcel"
              />
              <img
                src="/assets/icons/search.svg"
                className="absolute top-[10px] right-[14px]"
                alt="Search icon"
              />
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto">
          <table className="w-full text-base text-left">
            <thead className="bg-[#F9FAFC]">
              <tr>
                {HEAD?.map((itm, idx) => (
                  <th
                    key={idx + 1}
                    scope="col"
                    className="px-6 py-4 text-[#8F95B2]"
                  >
                    {itm}
                  </th>
                ))}
                <th
                  scope="col"
                  className="px-6 py-4 text-[#8F95B2]"
                ></th>
              </tr>
            </thead>
            <tbody>
              {_data?.map((itm) => (
                <tr
                  className="border-b"
                  key={itm?._id}
                >
                  <th
                    scope="row"
                    className="px-4 md:px-6py-4 text-[#081735]"
                  >
                    {itm?.weight} Lbs ({itm?.dimensions["length"]} *{" "}
                    {itm?.dimensions?.width} * {itm?.dimensions?.height})
                  </th>
                  <td className="px-4 md:px-6 py-4">
                    <p className="font-[600] text-lg">
                      {itm?.shipping_address?.name}
                    </p>
                    <p>{itm?.shipping_address?.street}</p>
                    <p>{itm?.shipping_address?.street2}</p>
                    <p>{itm?.shipping_address?.state}</p>
                    <p>{itm?.shipping_address?.city}</p>
                    <span className="text-sm text-sec_text">
                      {itm?.shipping_address?.phone_number}
                    </span>
                  </td>
                  <td className="px-4 md:px-6 py-4">
                    <p className="text-lg font-[600]">
                      {itm?.return_address?.name}
                    </p>
                    <p>{itm?.return_address?.street}</p>
                    <p>{itm?.return_address?.street2}</p>
                    <p>{itm?.return_address?.state}</p>
                    <p>{itm?.return_address?.city}</p>
                    <span className="text-sm text-sec_text">
                      {itm?.return_address?.phone_number}
                    </span>
                  </td>

                  <td className="px-4 md:px-6 py-4">
                    <p
                      onClick={() => {
                        sessionStorage.setItem("shipment", JSON.stringify(itm));
                        history.push(`/add-tracking/${itm?._id}`);
                      }}
                      className="bg-[#F3F4F6] rounded-xl px-[6px] py-[10px] flex items-center justify-center gap-2 cursor-pointer max-w-[176px]"
                    >
                      <img
                        src="/assets/icons/track.svg"
                        alt="Track"
                      />
                      <span className="font-[500]">Add Tracking</span>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
