import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "./utils/constants";

export default function Public({ component: Cmp, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get(REACT_APP_KEY_NAME) === undefined ? (
          <Cmp {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}
