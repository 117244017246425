import React from "react";
import { Link } from "react-router-dom";
import { REACT_APP_KEY_NAME } from "../utils/constants";
import Cookies from "js-cookie";

const Header = () => {
  const isLoggedIn =
    Cookies.get(REACT_APP_KEY_NAME) && localStorage.getItem(REACT_APP_KEY_NAME);

  const logout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.href = "/";
  };

  return (
    <header className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-3 md:py-4">
      <Link to="/"></Link>
      <div className="flex items-center gap-3">
        <div>
          {isLoggedIn && (
            <a
              href="#"
              onClick={logout}
              className="font-[700] text-base 2xl:text-xl text-primary p-2 sm:p-4"
            >
              Logout
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
