import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useHistory, useParams } from "react-router-dom";
import Button from "../components/Button";
import Api from "../api/api";
import Loader from "../components/Loader";

const Tracking = () => {
  const shipment = sessionStorage.getItem("shipment")
    ? JSON.parse(sessionStorage.getItem("shipment"))
    : {};

  const { id } = useParams();
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [_data, _setData] = useState({ ...shipment });
  const [data, setData] = useState({
    tracking_number:
      shipment?.tracking_number === "PENDING"
        ? "1Z"
        : shipment?.tracking_number,
    label_url:
      shipment?.label_url === "PENDING" ? "" : shipment?.tracking_number,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisable(true);

    const res = await Api.updateShipment(id, data);
    if (res.status === 200) {
      setIsDisable(false);
      history.push("/");
    } else {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("shipment");
    };
  }, []);

  return (
    <>
      <Header />
      <main className="w-full py-7 sm:py-14 max-w-[1367px] mx-auto px-4 xl:px-6">
        <div className="mb-3">
          <p>Weight: {_data?.weight} Lbs</p>
          <p>Length: {_data?.dimensions["length"]}</p>
          <p>Width: {_data?.dimensions?.width}</p>
          <p>Height: {_data?.dimensions?.height}</p>
        </div>
        <div className="flex w-full flex-wrap justify-between gap-4 mb-8">
          <div className="bg-secondary rounded-lg w-full md:w-[48.5%] p-4 md:p-8">
            <h2 className="text-xl font-semibold mb-3">
              Sender (Return) Details
            </h2>
            <div className="w-full bg-white rounded p-[8px_16px] mt-1 text-base 2xl:text-xl border border-[#D8D8D8]">
              <p>
                {_data?.shipping_address?.name}
                <br />
                {_data?.shipping_address?.street}
                <br />
                {_data?.shipping_address?.stree2}
                <br />
                <br />
                {_data?.shipping_address?.state}
                <br />
                {_data?.shipping_address?.city}
                <br />
                {_data?.shipping_address?.zipcode}
                <br />
                {_data?.shipping_address?.phone_number}
              </p>
            </div>
          </div>
          <div className="bg-secondary rounded-lg w-full md:w-[48.5%] p-4 md:p-8">
            <h2 className="text-xl font-semibold mb-3">
              Recipient (Receiver) Details
            </h2>
            <div className="w-full bg-white rounded p-[8px_16px] mt-1 text-base 2xl:text-xl border border-[#D8D8D8]">
              <p>
                {_data?.return_address?.name}
                <br />
                {_data?.return_address?.street}
                <br />
                {_data?.return_address?.street2}
                <br />
                <br />
                {_data?.return_address?.state}
                <br />
                {_data?.return_address?.city}
                <br />
                {_data?.return_address?.zipcode}
                <br />
                {_data?.return_address?.phone_number}
              </p>
            </div>
          </div>
        </div>
        <div className="grid place-items-center w-full">
          <form
            onSubmit={submitHandle}
            className="w-full max-w-[500px] bg-secondary p-4 sm:p-8 rounded-lg"
          >
            <p className="font-semibold text-xl mb-3">Add Tracking</p>
            <div className="mb-4">
              <label className="font-medium">
                Tracking Number (starts with 1Z)
              </label>
              <input
                value={data.tracking_number}
                name="tracking_number"
                onChange={handleChange}
                type="text"
                className="w-full bg-white rounded p-[8px_16px] mt-1 text-base 2xl:text-xl border border-[#D8D8D8]"
                required
              />
            </div>
            <div className="mb-6">
              <label className="font-medium">Tracking Label URL</label>
              <input
                value={data.label_url}
                name="label_url"
                onChange={handleChange}
                type="text"
                className="w-full bg-white rounded p-[8px_16px] mt-1 text-base 2xl:text-xl border border-[#D8D8D8]"
                required
              />
            </div>
            <Button
              title="Login to your account"
              width="full"
              type="submit"
              disabled={isDisable}
            >
              {!isDisable ? "Add and move to next package" : <Loader />}
            </Button>
          </form>
        </div>
      </main>
    </>
  );
};

export default Tracking;
