import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Tracking from "../pages/tracking";

export const PROTECTED_ROUTES = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/add-tracking/:id",
    component: Tracking,
  },
];

export const PUBLIC_ROUTES = [
  {
    path: "/login",
    component: Login,
  },
];
