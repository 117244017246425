import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import Api, { setAuthHeader } from "../api/api";
import Loader from "../components/Loader";

const Login = () => {
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [key, setKey] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisable(true);

    setAuthHeader(key);
    const res = await Api.getShipments();
    if (res.status === 200) {
      setIsDisable(false);
      history.push("/");
    } else {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setKey("");
      setIsDisable(false);
    };
  }, []);

  return (
    <div className="h-[100vh]">
      <div className="grid place-items-center w-full h-[100%] p-4">
        <form
          onSubmit={submitHandle}
          className="w-full max-w-[500px] bg-secondary p-4 sm:p-8 rounded-lg"
        >
          <p className="font-semibold text-xl mb-3">Admin Key</p>
          <label className="font-medium">Enter Admin Key</label>
          <input
            value={key}
            onChange={(e) => setKey(e.target.value)}
            type="text"
            className="w-full bg-white rounded p-[8px_16px] mt-1 text-base 2xl:text-xl border border-[#D8D8D8]"
            required
          />
          <Button
            title="Login to your account"
            width="full"
            type="submit"
            style={{ marginTop: "1.5rem" }}
            disabled={isDisable}
          >
            {!isDisable ? "Login" : <Loader />}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
