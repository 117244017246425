import axios from "axios";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../utils/constants";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getJwt = () => {
  if (
    Cookies.get(REACT_APP_KEY_NAME) !== undefined &&
    localStorage.getItem(REACT_APP_KEY_NAME)
  ) {
    return Cookies.get(REACT_APP_KEY_NAME);
  } else {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
  }
};

const setJwt = (key) => {
  Cookies.set(REACT_APP_KEY_NAME, key, {
    expires: 1,
    path: "/",
    secure: false,
  });
  localStorage.setItem(REACT_APP_KEY_NAME, key);
};

export const setAuthHeader = (key) => {
  axios.defaults.headers.common["x-api-key"] = `${key}`;
  setJwt(key);
};

if (getJwt()) {
  setAuthHeader(getJwt());
}

export default class Api {
  //get shipments
  static getShipments = async () => {
    try {
      const res = await axios.get(`/admin/pending-shipments`);
      return res;
    } catch (error) {
      if ([401, 403]?.includes(error.response?.status)) {
        alert(error.response?.data?.error);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/login";
      }
      return error.response.data;
    }
  };

  //update shipment
  static updateShipment = async (id, data) => {
    try {
      const res = await axios.patch(`/admin/update-shipment/${id}`, data);
      return res;
    } catch (error) {
      alert(error.response?.data?.error);
      if ([401, 403]?.includes(error.response?.status)) {
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.href = "/login";
      }
      return error.response.data;
    }
  };
}
